import { HTTP } from "./http-common";
import store from "@/store/index";

export function getAzucar() {
  return new Promise(function(resolve) {
    HTTP.get("/")
      .then((response) => {
        store.dispatch("addRequest", {
          method: "GET",
          url: "/",
          status: true,
          params: {},
          body: {},
          response: response.data,
        });        
        resolve(response);
      })
      .catch((err) => {
        store.dispatch("addRequest", {
          method: "GET",
          url: "/",
          status: false,
          params: {},
          body: {},
          response: err.message,
        });
        resolve(err);
      });
  });
}